import React from 'react'

export default function ServicesScreen() {
    return (
        <section className="container">
            <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
                <h4 className='text-center mb-4'>Servicios</h4>
                <div className="col-12 col-lg-8 offset-lg-2 mb-4">
                    <p align="justify" className="fw-bold fst-italic">Horario de atención:</p>
                    <p align="justify" className="fw-lighter fst-italic">El CEDEC presta su servicio de atención a usuarios de lunes a viernes en horario de 07:30 a.m. a 07:30 p.m., sin cerrar al medio dia.</p>
                    <p align="justify" className="fw-lighter fst-italic">Entre  los servicios principales que brinda el CEDEC están :<br />
                    </p>
                    <p align="justify" className="fw-bold fst-italic">Catálogos:</p>
                    <p align="justify" className="fw-bold fst-italic">Catálogo electrónico:</p>
                    <p align="justify" className="fw-lighter fst-italic">Para búsqueda y recuperación de información el CEDEC cuenta con el catálogo electrónico, en el cual se encuentra la información completa de nuestra base de datos.</p>
                    <p align="justify" className="fw-bold fst-italic">Catálogo bibliográfico (fichero):</p>
                    <p align="justify" className="fw-lighter fst-italic">Contiene tarjetas bibliográficos de libros ordenadas alfabeticamente.</p>
                    <p align="justify" className="fw-lighter fst-italic"><span className="fw-bold fst-italic">Préstamo  de Material Bibliográfico:</span></p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        <span className="fw-bold fst-italic">Préstamo  Interno:</span> El material es utilizado únicamente para consulta en sala.  <br />
                        Tienen  acceso a este servicio: </p>
                    <div align="justify">
                        <ul type="disc">
                            <li className="fw-lighter fst-italic">Egresados       de la Escuela       de Ciencia Política, USAC. </li>
                            <li className="fw-lighter fst-italic">Personal       administrativo de la Universidad de San Carlos de Guatemala. </li>
                            <li className="fw-lighter fst-italic">Alumnos       de Otras Facultades de la Universidad de San Carlos de Guatemala. </li>
                            <li className="fw-lighter fst-italic">Alumnos       de Otras Universidades. </li>
                            <li className="fw-lighter fst-italic">
                                <div align="left">Público       en General.</div>
                            </li>
                        </ul>
                    </div>
                    <p align="justify" className="fw-lighter fst-italic"><span className="fw-bold fst-italic">Requisitos:</span> </p>
                    <ul>
                        <li className="fw-lighter fst-italic">
                            <div align="justify"><span className="fw-lighter fst-italic">Registrarse como usuario con el personal del CEDEC. </span></div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Llenar la boleta con los datos del libro y datos personales requeridos.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify"><span className="fw-lighter fst-italic">Presentar carnet vigente y en buen estado en el caso de estudiantes y personal administrativo de la USAC</span>. En el caso de público en general presentar DPI o licencia de conducir vigente.</div>
                        </li>
                    </ul>
                    <p align="justify" className="fw-lighter fst-italic"><strong><br />
                        <span className="fw-bold fst-italic">Préstamo  Externo: </span></strong></p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        <span className="fw-bold fst-italic">Tienen  acceso a préstamo externo:</span> </p>
                    <div align="justify">
                        <ul type="disc">
                            <li className="fw-lighter fst-italic">Estudiantes       de pre-grado y post-grado de la Escuela       de Ciencia Política, USAC inscritos durante el presente ciclo.</li>
                            <li className="fw-lighter fst-italic">Docentes       e Investigadores de la        Escuela de Ciencia Política. </li>
                            <li className="fw-lighter fst-italic">Personal       Administrativo de la        Escuela de Ciencia Política.</li>
                        </ul>
                    </div>
                    <p align="justify" className="fw-lighter fst-italic"><span className="fw-bold fst-italic">Requisitos:</span></p>
                    <ul>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Registrarse como usuario con el personal del CEDEC. </div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Llenar la boleta con los datos del libro y datos personales requeridos.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">En  el caso de los estudiantes, presentar carnet vigente y en buen estado.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Docentes y personal administrativo  presentar carnet que los identifique como trabajadores de la Escuela de Ciencia  Politica.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">En ambos casos no se aceptará ningún otro documento.</div>
                        </li>
                    </ul>
                    <p align="justify" className="fw-lighter fst-italic">&nbsp;</p>
                    <p align="justify" className="fw-bold fst-italic"><strong>Tiempo límite para préstamo externo:</strong></p>
                    <ul>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Estudiantes regulares: pueden llevarse el material por 7 días hábiles.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Estudiantes de Examen Prvida, EPS, Maestría: 7 días hábiles.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">En ambos casos podrán       llevarse en préstamo externo hasta 7 materiales bibliográficos, (3 libros más  4 tesis o 4 documentos).</div>
                        </li>
                    </ul>
                    <div align="justify">
                        <p>&nbsp;</p>
                        <p>Renovación de préstamos:</p>
                        <ul>
                            <li className="fw-lighter fst-italic">Todo préstamo externo puede ser renovado hasta por un mes, si el material no es requerido por otro(a) estudiante.</li>
                        </ul>
                        <p>Reserva de material vía internet:</p>
                        <ul>
                            <li className="fw-lighter fst-italic">La reserva de material bibliográfico está habilitada para estudiantes, docentes y personal adminsitrativo de la Escuela de Ciencia Política, para lo cual es necesario registrarse y obtener su PIN en el CEDEC.</li>
                        </ul>
                    </div>
                    <div align="justify"><strong><br />
                        <span className="fw-bold fst-italic">Préstamo  interbibliotecario:</span></strong></div>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        El servicio de préstamo  interbibliotecario tiene como objetivo fundamental proporcionar a los docentes  e investigadores de la Escuela de Ciencia Política,  aquellos documentos que no se encuentren  disponibles en el Centro de Documentación.</p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        El CEDEC  puede actuar como peticionario, es decir, solicitando a otras bibliotecas  externas los documentos necesarios para sus usuarios y como Centro proveedor,  facilitando a otras bibliotecas fondos de sus colecciones para satisfacer las  necesidades documentales de sus usuarios.</p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Los  documentos originales prestados por otras bibliotecas no deben retirarse del  Centro de Documentación, respetando el plazo del préstamo señalado por cada  biblioteca. </p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Actualmente  se realizan préstamos interbibliotecarios con los siguientes Centros de  Documentación y Bibliotecas:  INCEP,  FLACSO- GUATEMALA.</p>
                    <p align="justify" className="fw-bold fst-italic">Material de circulación restringida:</p>
                    <p align="justify" className="fw-lighter fst-italic">Existe material que por su valor bibliográfico solo puede consultarse en sala. Ejemplo:</p>
                    <ul>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Diccionarios y enciclopedias.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Libros del cual solo existe un ejemplar.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">CDS.</div>
                        </li>
                        <li className="fw-lighter fst-italic">
                            <div align="justify">Revistas.</div>
                        </li>
                    </ul>
                    <p align="justify" className="fw-lighter fst-italic"><span className="fw-bold fst-italic">Servicio  de Referencia:</span></p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Es  el servicio que orienta al usuario en la búsqueda de información precisa,  ofrece servicios de consulta en bases de datos, brinda apoyo a la  investigación.</p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        <span className="fw-bold fst-italic">Servicio  de Reprografía:</span> </p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Este  servicio posibilita la reproducción de material bibliográfico, excepto de  aquellos materiales que a criterio de la jefatura quedan excluidos por razón de  antigüedad, su estado de conservación o por constituir obras valiosas o  insustituibles.<br />
                        Servicio de  Internet: El CEDEC cuenta con equipo de computación en la sala de lectura con  servicio de internet inalámbrico.  Así  mismo cuenta con un área para uso de computadoras personales.<br />
                    </p>
                    <p align="justify" className="fw-lighter fst-italic"><br />

                        Servicio de  Internet: El CEDEC cuenta con equipo de computación en la sala de lectura con  servicio de internet inalámbrico.  Así  mismo cuenta con un área para uso de computadoras personales.
                    </p>
                    <div align="justify"></div>
                    <p align="justify" className="fw-lighter fst-italic"><span className="fw-bold fst-italic">Multas:</span> </p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Las  multas se aplican tanto a estudiantes, docentes, investigadores, y personal  administrativo. </p>
                    <div align="justify">
                        <ul type="disc">
                            <li className="fw-lighter fst-italic">Se       cobrará la cantidad de Q 1.00 de mora por cada día de atraso y por cada       material prestado. </li>
                            <li className="fw-lighter fst-italic">Si       un libro que ha sido prestado en sala no es devuelto el mismo día, se       cobrará la cantidad de Q 10.00 de mora diarios. </li>
                        </ul>
                    </div>
                    <p align="justify" className="fw-lighter fst-italic"><span className="fw-bold fst-italic">Solvencia  del Centro de Documentación: </span></p>
                    <p align="justify" className="fw-lighter fst-italic"><br />
                        Es  requisito indispensable presentar solvencia del CEDEC para los siguientes  servicios: </p>
                    <div align="justify">
                        <ul type="disc">
                            <li className="fw-lighter fst-italic">Asignación       de cursos. </li>
                            <li className="fw-lighter fst-italic">Exámenes       finales de cursos. </li>
                            <li className="fw-lighter fst-italic">Examen       General de Grado (Privado). </li>
                            <li className="fw-lighter fst-italic">Examen       Público. </li>
                            <li className="fw-lighter fst-italic">
                                <div align="left">Obtención       de Finiquito (para Docentes, Investigadores y personal administrativo). </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
