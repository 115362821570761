import React from 'react'

export default function SubscriptionTable() {
    return (
        <div className='bg-white mx-0 mx-md-3 my-3 py-3 px-4 shadow-sm rounded'>
            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://clacso.redalyc.org/#/home'>
                        <img style={{ height: "auto", width: '262px' }} className="card-img-top" src="/opac/assets/subscriptions/redalyc1.png" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://www.scielo.org/php/index.php?lang=es'>
                        <img style={{ height: "auto", width: '100px' }} className="card-img-top" src="/opac/assets/subscriptions/logo-scielo-portal-no-label.svg" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://www.latindex.org/latindex/inicio'>
                        <img style={{ height: "auto", width: '262px' }} className="card-img-top" src="/opac/assets/subscriptions/logo_latindex.png" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='http://www.alasred.org/index.html'>
                        <img style={{ height: "auto", width: '262px' }} className="card-img-top" src="/opac/assets/subscriptions/revistalas.png" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://doaj.org/'>
                        <img style={{ height: "auto", width: '262px' }} className="card-img-top" src="/opac/assets/subscriptions/DOAJ.jpg" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://dialnet.unirioja.es/'>
                        <img style={{ height: "auto", width: '242px' }} className="card-img-top" src="/opac/assets/subscriptions/dialnet.png" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://clase.unam.mx/F?func=find-b-0&amp;local_base=cla01'>
                        <img style={{ height: "auto", width: '112px' }} className="card-img-top" src="/opac/assets/subscriptions/logoClase_00.jpg" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://nuso.org/edicion-digital/'>
                        <img style={{ height: "auto", width: '242px' }} className="card-img-top" src="/opac/assets/subscriptions/revista_ns.jpg" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='http://biblioteca.clacso.edu.ar/'>
                        <img style={{ height: "auto", width: '142px' }} className="card-img-top" src="/opac/assets/subscriptions/logo-clacso-esp.png" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://www.elibrary.imf.org/'>
                        <img style={{ height: "auto", width: '142px' }} className="card-img-top" src="/opac/assets/subscriptions/fmi.jpg" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://www.bancomundial.org/'>
                        <img style={{ height: "auto", width: '142px' }} className="card-img-top" src="/opac/assets/subscriptions/bancomundial.gif" alt="logo" /></a>
                </div>
                <div className='col-12 col-md-6 col-lg-4 card text-center py-3' style={{ minHeight: '120px' }}>
                    <a target='_blank' rel="noreferrer" href='https://search.ebscohost.com/login.aspx?authtype=ip,uid&custid=s6775430&groupid=main&profile=ehost'>
                        <img style={{ height: "auto", width: '142px' }} className="card-img-top" src="/opac/assets/subscriptions/ebsco.png" alt="logo" /></a>
                    <div className="card-body">
                        <p className='mb-0 fw-bold'>EBSCO</p>
                        <p className='mb-0'>Bases de datos de investigación, revistas, libros electrónicos. Usuario: usacebsco* Clave: Acceso2023*</p>
                        </div>
                </div>
            </div>
        </div>

    )
}
